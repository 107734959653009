import * as React from 'react';
import { Link, useLocation } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Divider } from '@mui/material';
import { useTheme } from '@mui/styles';

import CustomLink from './CustomLink';
import { areRolesAllowed } from 'utils/roles';
import { Session } from 'utils';
import CenterFlexBox from './CenterFlexBox';
import { useTour } from 'contexts';
import LogoutButton from './LogoutButton';
import { AssessmentIcon, BatchIcon, DashboardIcon, MockIcon, Settings, StudentIcon } from './Icons';

const SideBarLinks = [
    {
        label: "Dashboard",
        path: '/home',
        allowedRoles: ["CLIENT", "SEP_CLIENT"],
        icon: DashboardIcon
    },
    {
        label: "Batches",
        path: '/batches',
        allowedRoles: ["CLIENT", "SEP_CLIENT"],
        icon: BatchIcon,
    },
    {
        label: "Assessments",
        path: '/assessments',
        allowedRoles: ["CLIENT", "SEP_CLIENT"],
        icon: AssessmentIcon
    },
    {
        label: "Mock Interview",
        path: '/personalizedInterview',
        allowedRoles: ["CLIENT"],
        icon: MockIcon
    },
    {
        label: "Students",
        path: '/students',
        allowedRoles: ["CLIENT", "SEP_CLIENT"],
        icon: StudentIcon,
    },
    {
        label: "Settings",
        path: '/settings',
        allowedRoles: ["CLIENT", "SEP_CLIENT"],
        icon: Settings,
    },
];;

const useStyles = makeStyles(theme => ({
    sideBarRoot: {
        bottom: "0px",
        left: "0px",
        position: "fixed",
        top: "0",
        zIndex: 200,
        backgroundColor: '#FFFFFF',
        boxShadow: '2px 0px 4px rgb(0 0 0 / 15%)',
        borderBottom: '1px solid rgb(0 0 0 / 15%)',
        display: "flex",
        flexDirection: "column",
        minWidth: 242,
        width: 242,
    },
    sideBar: {
        boxSizing: 'border-box',
        border: 'none',
        minWidth: 242,
    },
    logoContainer: {
        width: 200,
        boxShadow: '0px 0px 2px 0px #00000040',
        borderRadius: '4px',
        backgroundColor: 'white',
        margin: theme.spacing(6, 0),
        padding: theme.spacing(0, 3),
        overflow: 'hidden'
    },
    link: {
        textDecoration: 'none'
    },
    menuItem: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
        transition: "200ms ease-in-out",
        cursor: "pointer",
        padding: theme.spacing(5),
        "&:hover": {
            backgroundColor: theme.palette.neutral['clr-50'],
        }
    },
    activeMenuItem: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
        transition: "200ms ease-in-out",
        cursor: "pointer",
        padding: theme.spacing(5),
        backgroundColor: theme.palette.primary.main,
    },
    menuItemText: {
        transformOrigin: "0 50% 0",
        overflow: "hidden",
        height: "1.6em",
        marginLeft: '1em',
        color: "inherit",
        paddingTop: 2,
    },
    divider: {
        borderColor: theme.palette.neutral['clr-200']
    },
    tutorialBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(5, 0)
    },
    tutorialButton: {
        width: '181px',
        height: '44px',
        border: '1px solid #003E8C',
        borderRadius: theme.spacing(1),
        display: 'flex',
        gap: theme.spacing(1),
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }
}));

function MenuItem({ menu }) {
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();

    const MenuIcon = menu.icon;
    const [selected, setSelected] = React.useState(false);

    React.useEffect(() => {
        const locationPathname = window.location.pathname;
        const isSelected = locationPathname.includes(menu.path) || locationPathname.includes(menu?.defaultPath);
        setSelected(isSelected);
    }, [menu, location]);


    return areRolesAllowed(...menu.allowedRoles) ? (
        <>
            <Link to={menu.path} className={classes.link}>
                <Box className={selected ? classes.activeMenuItem : classes.menuItem}>
                    <MenuIcon
                        color={
                            selected
                                ? theme.palette.shades['clr-white-900']
                                : theme.palette.tertiary['clr-400']
                        }
                    />
                    <Typography
                        variant='h6-semiBold'
                        color={selected ? 'shades.clr-white-900' : 'neutral.clr-600'}
                    >
                        {menu.label}
                    </Typography>
                </Box>
            </Link>
        </>
    ) : null;
}

function TutorialButton() {
    const classes = useStyles();
    const { show } = useTour();

    return (
        <Box className={classes.tutorialBox}>
            <Box className={classes.tutorialButton} onClick={show} id="tutorial-button">
                <img src='https://assets.languify.in/images/play-icon.svg' alt='play' />
                <Typography
                    variant='h6-medium'
                    color='primary.main'
                >
                    Replay tutorial
                </Typography>
            </Box>
        </Box>
    );
}

export default function Sidebar() {
    const classes = useStyles();

    const {
        logoUrl,
        inprepLogoTransform: transform
    } = Session.getInprepLogoAndTransform();

    return (
        <Box className={classes.sideBarRoot}>
            <CenterFlexBox py={1} px={4} >
                <Link to="/home">
                    <Box style={{ height: '60px', width: '196px', overflow: 'hidden', margin: '32px', display: 'flex', justifyContent: 'center', boxShadow: '0px 0px 2px 0px #00000040' }}>
                        <img
                            src={(logoUrl) ? logoUrl : 'https://assets.languify.in/sep-powered-by-languify.svg'}
                            alt={Session?.user?.name || 'languify'}
                            style={{
                                height: '60px',
                                width: 'auto',
                                transform:
                                    `translate(${transform?.translateX || 0}px, 
                                    ${transform?.translateY || 0}px) 
                                    rotate(${transform?.rotation || 0}deg) 
                                    scale(${transform?.scale || 1})`
                            }}
                        />
                    </Box>
                </Link>
            </CenterFlexBox>
            <Box className={classes.sideBar}>
                {SideBarLinks.map((sideBarLink, index) => (
                    <MenuItem key={index} menu={sideBarLink} />
                ))}
                <Divider className={classes.divider} />
            </Box>
            <Box className={classes.bottomItems}>
                <LogoutButton />
                <Divider className={classes.divider} />
                <TutorialButton />
            </Box>
        </Box>
    );
}
