import React from 'react';
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    display: "flex",
    width: 'fit-content',
    justifyContent: "space-between",
    border: '1px solid',
    borderColor: theme.palette.neutral["clr-500"],
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    height: '44px'
  },
  tab: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    cursor: "pointer",
    transition: "400ms ease-in-out",
    padding: theme.spacing(3,5),
    borderColor: theme.palette.neutral["clr-500"],
    width:'132px'
  },
  activeTab: {
    backgroundColor: theme.palette.secondary.main
  }
}));

function Tabs({
  value,
  options = [],
  onChange = () => { },
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.root}>
      {
        options.map((option, index) => {
          return (
            <Box
              key={index}
              className={`${classes.tab} ${option._id === value ? classes.activeTab : ''}`}
              onClick={() => onChange(option._id)}
              style={{
                borderRight: index < options.length - 1 ? `1px solid ${theme.palette.neutral["clr-500"]}` : 'none'
              }}
              {...props}
            >
              {
                option.icon
                  ? <img src={option.icon} alt={option.label} />
                  : null
              }
              <Typography variant="body01-bold" color={'neutral.clr-800'}>
                {option?.label || index}
              </Typography>
            </Box>
          )
        })
      }
    </Box>
  );
}

export default Tabs;